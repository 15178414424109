<template>
  <div class="col-md-3">
    <div class="card module-card">
      <img
        v-if="schedule.image != null"
        :src="schedule.image"
        class="card-img-top"
        alt="..."
      />
      <img
        v-else
        src="../assets/maths-illus.png"
        class="card-img-top"
        alt="..."
      />
      <div class="card-body">
        <h5 class="card-title">{{ schedule.event.title }}</h5>
        <p class="card-text">
          <span>
            <i class="far fa-clock"></i>
          </span>
          {{ convertedTimeForUser }}
        </p>
        <a href="#" class="cta-play">
          <i class="fas fa-play"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Timezone from "@/services/Timezone";
export default {
  name: "EventScheduleCard",
  props: ["schedule"],
  computed: {
    convertedTimeForUser() {
      let convertedStartTime = Timezone.convertedTimeZone(
        this.schedule.event.start_time
      ).format("Do MMM, YYYY h:mm A");
      let convertedEndTime = Timezone.convertedTimeZone(
        this.schedule.event.end_time
      ).format("Do MMM, YYYY h:mm A");
      let convertedTime = convertedStartTime + " - " + convertedEndTime;
      return convertedTime;
    },
  },
};
</script>

<style lang="scss">
@import "@/style/module-card.scss";
</style>

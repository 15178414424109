<template>
  <div class="course-tab">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="myevent-tab"
          data-bs-toggle="tab"
          data-bs-target="#myevent"
          type="button"
          role="tab"
          aria-controls="myevent"
          aria-selected="true"
        >
          subscribed events
        </button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="myevent"
        role="tabpanel"
        aria-labelledby="myevent-tab"
      >
        <!-- <h6 class="day-header">Live/Upcoming Events</h6> -->
        <div class="row listing-row g-0">
          <div v-if="eventLoading" class="loading">Loading...</div>
          <div class="row" v-else-if="subscribedEvents">
            <EventScheduleCard
              v-for="event in subscribedEvents"
              :key="event.id"
              :schedule="event"
            />
            <div class="paging-wrapper py-3">
              <v-pagination
                v-model="eventPage"
                :pages="totalEventPages"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="eventPaginateUpdateHandler"
                v-if="totalEvents > 0"
              />
            </div>
            <EmptyList
              v-if="totalEvents == 0"
              messageText="No subscribed events found!"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventScheduleCard from "@/components/EventScheduleCard.vue";
import EmptyList from "@/components/EmptyList.vue";
import DashboardService from "@/services/DashboardService";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "EventTab",
  components: {
    EventScheduleCard,
    VPagination,
    EmptyList,
  },
  data() {
    return {
      eventLoading: true,
      // Initial page number for program if any
      eventPage: 1,
      currentEventPage: 1,
      totalEventPages: 0,
      totalEvents: 0,
      subscribedEvents: [],
    };
  },
  mounted() {
    this.fetchSubscribedEvents();
  },
  methods: {
    fetchSubscribedEvents() {
      let params = {
        page: this.currentEventPage,
      };
      DashboardService.getSubscribedEvents(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.eventLoading = false;
            this.subscribedEvents = response.data.events.data;
            // console.log(this.subscribedEvents);
            // works only when totalEventPages should be the last_page value
            this.totalEventPages = response.data.events.last_page;
            this.currentEventPage = response.data.events.current_page;
            this.totalEvents = response.data.events.total;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    eventPaginateUpdateHandler(selectedPage) {
      if (this.currentEventPage !== selectedPage) {
        this.currentEventPage = selectedPage;
        this.fetchSubscribedEvents();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/course-tab.scss";
</style>
